<script>
/* eslint-disable */
import PageHeader from '@/components/page-header';
import appConfig from '@/app.config';
import Layout from '../../layouts/main';

import {barChart} from './data-overview';

/**
 * Projects-overview component
 */
export default {
  page: {
    title: 'Projects Overview',
    meta: [{name: 'description', content: appConfig.description}],
  },
  components: {Layout, PageHeader},
  data() {
    return {
      barChart,
      title: 'Projects Overview',
      items: [
        {
          text: 'Projects',
          href: '/',
        },
        {
          text: 'Projects Overview',
          active: true,
        },
      ],
    };
  },
};
</script>

<template>
  <Layout>
    <PageHeader :items="items" :title="title"/>
    <div class="row">
      <div class="col-lg-8">
        <div class="card">
          <div class="card-body">
            <div class="d-flex">
              <img alt class="avatar-sm me-4" src="@/assets/images/companies/img-1.png"/>

              <div class="flex-grow-1 overflow-hidden">
                <h5 class="text-truncate font-size-15">Skote Dashboard UI</h5>
                <p class="text-muted">Separate existence is a myth. For science, music, sport,
                  etc.</p>
              </div>
            </div>

            <h5 class="font-size-15 mt-4">Project Details :</h5>

            <p
              class="text-muted"
            >To an English person, it will seem like simplified English, as a skeptical Cambridge
              friend of mine told me what Occidental is. The European languages are members of the
              same family. Their separate existence is a myth. For science, music, sport, etc,</p>

            <div class="text-muted mt-4">
              <p>
                <i class="mdi mdi-chevron-right text-primary me-1"></i> To achieve this, it would be
                necessary
              </p>
              <p>
                <i class="mdi mdi-chevron-right text-primary me-1"></i> Separate existence is a
                myth.
              </p>
              <p>
                <i class="mdi mdi-chevron-right text-primary me-1"></i> If several languages
                coalesce
              </p>
            </div>

            <div class="row task-dates">
              <div class="col-sm-4 col-6">
                <div class="mt-4">
                  <h5 class="font-size-14">
                    <i class="bx bx-calendar me-1 text-primary"></i> Start Date
                  </h5>
                  <p class="text-muted mb-0">08 Sept, 2019</p>
                </div>
              </div>

              <div class="col-sm-4 col-6">
                <div class="mt-4">
                  <h5 class="font-size-14">
                    <i class="bx bx-calendar-check me-1 text-primary"></i> Due Date
                  </h5>
                  <p class="text-muted mb-0">12 Oct, 2019</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- end col -->

      <div class="col-lg-4">
        <div class="card">
          <div class="card-body">
            <h4 class="card-title mb-4">Team Members</h4>

            <div class="table-responsive">
              <table class="table table-nowrap align-middle">
                <tbody>
                <tr>
                  <td style="width: 50px;">
                    <img
                      alt
                      class="rounded-circle avatar-xs"
                      src="@/assets/images/users/avatar-2.jpg"
                    />
                  </td>
                  <td>
                    <h5 class="font-size-14 m-0">
                      <a class="text-dark" href>Daniel Canales</a>
                    </h5>
                  </td>
                  <td>
                    <div>
                      <a
                        class="badge bg-primary bg-soft text-primary font-size-11"
                        href="javascript: void(0);"
                      >Frontend</a>
                      <a
                        class="badge bg-soft bg-primary text-primary font-size-11 ms-1"
                        href="javascript: void(0);"
                      >UI</a>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td>
                    <img
                      alt
                      class="rounded-circle avatar-xs"
                      src="@/assets/images/users/avatar-1.jpg"
                    />
                  </td>
                  <td>
                    <h5 class="font-size-14 m-0">
                      <a class="text-dark" href>Jennifer Walker</a>
                    </h5>
                  </td>
                  <td>
                    <div>
                      <a
                        class="badge bg-primary bg-soft text-primary font-size-11"
                        href="javascript: void(0);"
                      >UI / UX</a>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td>
                    <div class="avatar-xs">
                        <span
                          class="avatar-title rounded-circle bg-soft-primary text-white font-size-16"
                        >C</span>
                    </div>
                  </td>
                  <td>
                    <h5 class="font-size-14 m-0">
                      <a class="text-dark" href>Carl Mackay</a>
                    </h5>
                  </td>
                  <td>
                    <div>
                      <a
                        class="badge bg-primary bg-soft text-primary font-size-11"
                        href="javascript: void(0);"
                      >Backend</a>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td>
                    <img
                      alt
                      class="rounded-circle avatar-xs"
                      src="@/assets/images/users/avatar-4.jpg"
                    />
                  </td>
                  <td>
                    <h5 class="font-size-14 m-0">
                      <a class="text-dark" href>Janice Cole</a>
                    </h5>
                  </td>
                  <td>
                    <div>
                      <a
                        class="badge bg-primary bg-soft text-primary font-size-11"
                        href="javascript: void(0);"
                      >Frontend</a>
                      <a
                        class="badge bg-primary bg-soft text-primary font-size-11 ms-1"
                        href="javascript: void(0);"
                      >UI</a>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td>
                    <div class="avatar-xs">
                        <span
                          class="avatar-title rounded-circle bg-soft-primary text-white font-size-16"
                        >T</span>
                    </div>
                  </td>
                  <td>
                    <h5 class="font-size-14 m-0">
                      <a class="text-dark" href>Tony Brafford</a>
                    </h5>
                  </td>
                  <td>
                    <div>
                      <a
                        class="badge bg-primary bg-soft text-primary font-size-11"
                        href="javascript: void(0);"
                      >Backend</a>
                    </div>
                  </td>
                </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
      <!-- end col -->
    </div>
    <!-- end row -->

    <div class="row">
      <div class="col-lg-4">
        <div class="card">
          <div class="card-body">
            <h4 class="card-title mb-4">Overview</h4>

            <div id="overview-chart" class="apex-charts" dir="ltr"></div>
            <apexchart
              :options="barChart.chartOptions"
              :series="barChart.series"
              class="apex-charts"
              height="280"
              type="bar"
            ></apexchart>
          </div>
        </div>
      </div>
      <!-- end col -->

      <div class="col-lg-4">
        <div class="card">
          <div class="card-body">
            <h4 class="card-title mb-4">Attached Files</h4>
            <div class="table-responsive">
              <table class="table table-nowrap table-centered table-hover mb-0 align-middle">
                <tbody>
                <tr>
                  <td style="width: 45px;">
                    <div class="avatar-sm">
                        <span
                          class="avatar-title rounded-circle bg-soft bg-primary text-primary font-size-24"
                        >
                          <i class="bx bxs-file-doc"></i>
                        </span>
                    </div>
                  </td>
                  <td>
                    <h5 class="font-size-14 mb-1">
                      <a class="text-dark" href="javascript: void(0);">Skote Landing.Zip</a>
                    </h5>
                    <small>Size : 3.25 MB</small>
                  </td>
                  <td>
                    <div class="text-center">
                      <a class="text-dark" href="javascript: void(0);">
                        <i class="bx bx-download h3 m-0"></i>
                      </a>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td>
                    <div class="avatar-sm">
                        <span
                          class="avatar-title rounded-circle bg-soft bg-primary text-primary font-size-24"
                        >
                          <i class="bx bxs-file-doc"></i>
                        </span>
                    </div>
                  </td>
                  <td>
                    <h5 class="font-size-14 mb-1">
                      <a class="text-dark" href="javascript: void(0);">Skote Admin.Zip</a>
                    </h5>
                    <small>Size : 3.15 MB</small>
                  </td>
                  <td>
                    <div class="text-center">
                      <a class="text-dark" href="javascript: void(0);">
                        <i class="bx bx-download h3 m-0"></i>
                      </a>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td>
                    <div class="avatar-sm">
                        <span
                          class="avatar-title rounded-circle bg-soft bg-primary text-primary font-size-24"
                        >
                          <i class="bx bxs-file-doc"></i>
                        </span>
                    </div>
                  </td>
                  <td>
                    <h5 class="font-size-14 mb-1">
                      <a class="text-dark" href="javascript: void(0);">Skote Logo.Zip</a>
                    </h5>
                    <small>Size : 2.02 MB</small>
                  </td>
                  <td>
                    <div class="text-center">
                      <a class="text-dark" href="javascript: void(0);">
                        <i class="bx bx-download h3 m-0"></i>
                      </a>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td>
                    <div class="avatar-sm">
                        <span
                          class="avatar-title rounded-circle bg-soft bg-primary text-primary font-size-24"
                        >
                          <i class="bx bxs-file-doc"></i>
                        </span>
                    </div>
                  </td>
                  <td>
                    <h5 class="font-size-14">
                      <a class="text-dark" href="javascript: void(0);">Veltrix admin.Zip</a>
                    </h5>
                    <small>Size : 2.25 MB</small>
                  </td>
                  <td>
                    <div class="text-center">
                      <a class="text-dark" href="javascript: void(0);">
                        <i class="bx bx-download h3 m-0"></i>
                      </a>
                    </div>
                  </td>
                </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
      <!-- end col -->

      <div class="col-lg-4">
        <div class="card">
          <div class="card-body">
            <h4 class="card-title mb-4">Comments</h4>

            <div class="d-flex mb-4">
              <div class="me-3">
                <img
                  alt
                  class="media-object rounded-circle avatar-xs"
                  src="@/assets/images/users/avatar-2.jpg"
                />
              </div>
              <div class="flex-grow-1">
                <h5 class="font-size-13 mb-1">David Lambert</h5>
                <p class="text-muted mb-1">Separate existence is a myth.</p>
              </div>
              <div class="ms-3">
                <a class="text-primary" href>Reply</a>
              </div>
            </div>

            <div class="d-flex mb-4">
              <div class="me-3">
                <img
                  alt
                  class="media-object rounded-circle avatar-xs"
                  src="@/assets/images/users/avatar-3.jpg"
                />
              </div>
              <div class="flex-grow-1">
                <h5 class="font-size-13 mb-1">Steve Foster</h5>
                <p class="text-muted mb-1">
                  <a class="text-success" href>@Henry</a>
                  To an English person it will like simplified
                </p>
                <div class="d-flex mt-3">
                  <div class="avatar-xs me-3">
                    <span
                      class="avatar-title rounded-circle bg-soft bg-primary text-primary font-size-16"
                    >J</span>
                  </div>
                  <div class="flex-grow-1">
                    <h5 class="font-size-13 mb-1">Jeffrey Walker</h5>
                    <p class="text-muted mb-1">as a skeptical Cambridge friend</p>
                  </div>
                  <div class="ms-3">
                    <a class="text-primary" href>Reply</a>
                  </div>
                </div>
              </div>
              <div class="ms-3">
                <a class="text-primary" href>Reply</a>
              </div>
            </div>

            <div class="d-flex mb-4">
              <div class="avatar-xs me-3">
                <span
                  class="avatar-title rounded-circle bg-soft bg-primary text-primary font-size-16"
                >S</span>
              </div>
              <div class="flex-grow-1">
                <h5 class="font-size-13 mb-1">Steven Carlson</h5>
                <p class="text-muted mb-1">Separate existence is a myth.</p>
              </div>
              <div class="ms-3">
                <a class="text-primary" href>Reply</a>
              </div>
            </div>

            <div class="text-center mt-4 pt-2">
              <a class="btn btn-primary btn-sm" href="javascript: void(0);">View more</a>
            </div>
          </div>
        </div>
      </div>
      <!-- end col -->
    </div>
    <!-- end row -->
  </Layout>
</template>
